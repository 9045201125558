import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/dashboard',
    name: 'home',
    meta: { requiresAdminAuth: true },
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/LoginView.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/login/ForgotPassword.vue'),
  },
  {
    path: '/new-password',
    name: 'passwordReset',
    component: () => import(/* webpackChunkName: "new-password" */ '../views/login/NewPassword.vue'),
  },
  {
    path: '/users',
    name: 'users',
    meta: { requiresAdminAuth: true },
    component: () => import(/* webpackChunkName: "users" */ '../views/users/UserList.vue'),
  },
  {
    path: '/produtos',
    name: 'products',
    meta: { requiresAdminAuth: true },
    component: () => import(/* webpackChunkName: "products" */ '../views/products/ProductList.vue'),
  },
  {
    path: '/pacotes',
    name: 'packs',
    meta: { requiresAdminAuth: true },
    component: () => import(/* webpackChunkName: "pack" */ '../views/packs/PackList.vue'),
  },
  {
    path: '/vendas',
    name: 'vendas',
    meta: { requiresAdminAuth: true },
    component: () => import(/* webpackChunkName: "vendas" */ '../views/vendas/SalesPage.vue'),
  },
  {
    path: '/seriais',
    name: 'seriais',
    meta: { requiresAdminAuth: true },
    component: () => import(/* webpackChunkName: "vendas" */ '../views/serials/SerialList.vue'),
  },
  {
    path: '/configuracoes',
    name: 'configs',
    meta: { requiresAdminAuth: true },
    component: () => import(/* webpackChunkName: "configs" */ '../views/config/ConfigurationView.vue'),
  },
  {
    path: '/',
    name: 'ecommerce',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "ecommerce" */ '../views/ecommerce/home/HomePage.vue'),
  },
  {
    path: '/ecommerce/produto/:id',
    name: 'ecommerce',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "ecommerce-product" */ '../views/ecommerce/ItemPage.vue'),
  },
  {
    path: '/ecommerce/pacote/:id',
    name: 'ecommerce',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "ecommerce-pack" */ '../views/ecommerce/ItemPage.vue'),
  },
  {
    path: '/ecommerce/carrinho',
    name: 'cart',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "cart" */ '../views/ecommerce/CartPage.vue'),
  },
  {
    path: '/ecommerce/minha-conta',
    name: 'account',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "cart" */ '../views/ecommerce/myAccount/AccountPage.vue'),
  },
  {
    path: '/ecommerce/meus-pedidos',
    name: 'orders',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "cart" */ '../views/ecommerce/myOrders/OrdersPage.vue'),
  },
  {
    path: '/ecommerce/checkout/:id',
    name: 'checkout',
    // meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "checkout" */ '../views/ecommerce/CheckoutPage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const user = store.state.user;
  const type = user?.tipo;
  const id = user?.id;

  if (to.meta.requiresAdminAuth) {
    (type === 'admin') ? next() : next('/login'); //cleocir
  } else if (to.meta.requiresAuth) {
    id ? next() : next('/login'); 
  } else {
    next();
  }
});

export default router;
