<template>
    <v-dialog
        v-model="dialog"
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
        @keydown.enter="agree"
        persistent
    >
        <v-card>
            <v-toolbar dark :color="options.color" dense flat>
                <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
            <v-card-actions class="pt-0">
                <v-btn color="grey" text @click.native="cancel">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn :color="options.color" @click.native="agree" class="white--text">Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// https://gist.github.com/eolant/ba0f8a5c9135d1a146e1db575276177d
export default {
    name: 'ConfirmDialog',

    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
            color: 'primary',
            width: 320,
            zIndex: 200
        }
    }),

    methods: {
        open(title, message, options) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.options = Object.assign(this.options, options);

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        agree() {
            this.resolve(true);
            this.dialog = false;
        },

        cancel() {
            this.resolve(false);
            this.dialog = false;
        }
    }
}
</script>
