<template>
  <div id="app">
    <div v-if="loading">{{ msg }}</div>
    <router-view v-else />
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog';

export default {
  name: 'App',

  components: {
    ConfirmDialog,
  },

  data: () => ({
    loading: true,
    msg: 'Carregando, aguarde...',
  }),

  async mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
    this.cartRetrieve();
    await this.getConfiguracoes();
  },

  methods: {
    cartRetrieve() {
      const cart = JSON.parse(localStorage.getItem('rb-cart') || null) || [];
      this.$store.commit('setCart', cart);
    },

    async getConfiguracoes() {
      this.loading = true;
      this.$http.get('configuracoes/1').then(resp => {
        if (resp.data.type === 'warning') {
          this.$toast.error(resp.data.msg);
          return;
        }

        this.$store.commit('setConfig', resp.data.data);
        this.loading = false;
      }).catch(() => {
        this.$toast.error('Não foi possivel concluir a operação');
      });
    },
  }
}
</script>

<style lang="scss">
#app {
  $font-family-base: 'Montserrat', sans-serif;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
