import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cart: [],
    config: {},
    user: {},
    isAdmin: false,
  },

  getters: {
    getCart: state => {
      return state.cart;
    },

    getUser: state => {
      return state.user;
    },

    isAdmin: state => {
      return state.isAdmin;
    },
  },

  mutations: {
    setCart(state, cart) {
      state.cart = cart;
      localStorage.setItem('rb-cart', JSON.stringify(state.cart));
    },

    addToCart(state, item) {
      const finded = state.cart
        .filter(i => i.tipo === item.tipo)
        .find(i => i.id == item.id);

      if (finded) {
        finded.quantidade += item.quantidade;
      } else {
        state.cart.push(item);
      }

      localStorage.setItem('rb-cart', JSON.stringify(state.cart));
    },

    removeFromCart(state, id) {
      const index = state.cart.findIndex(e => e.id === id);
      if (index > -1) {
        state.cart.splice(index, 1);
      }

      localStorage.setItem('rb-cart', JSON.stringify(state.cart));
    },

    setConfig(state, config) {
      state.config = config;
    },

    setAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },

    setUser(state, user) {
      state.user = user;
    },
  },

  actions: {
  },

  modules: {
  },
})
